const API_URLS = {
  LOGIN: "api/login/",
  REFRESH_TOKEN: "api/token/refresh/",
  CHANGE_PASSWORD: "api/change-password/",
  FORGOT_PASSWORD: "api/forgot-password/",
  RESET_PASSWORD: "api/reset-password/",
  COMPANY: "api/{companyId}/",
  USERS: "api/users/{userId}/",
  COMPANY_USERS: "api/{companyId}/users/",
  PROJECTS: "api/{companyId}/projects/",
  ALL_CODE_LIST_DELTA:
    "api/{companyId}/projects/{projectId}/download-all-code-delta-excel/",
  ALL_CODE_LIST_FULL:
    "api/{companyId}/projects/{projectId}/download-all-code-excel/",
  DEFINITIONS: "api/{companyId}/projects/{projectId}/mappings/",
  SHORT_CODES: "api/{companyId}/projects/{projectId}/short-codes/",
  DIRECTION_CHANGES: "api/{companyId}/projects/{projectId}/direction-changes/",
  PIPE_THICKNESSES: "api/{companyId}/projects/{projectId}/pipe-thicknesses/",
  MINIMUM_PIPE_LENGTH_RULES:
    "api/{companyId}/projects/{projectId}/minimum-pipe-length-rules/",
  MINIMUM_PIPE_LENGTH_PURCHASES:
    "api/{companyId}/projects/{projectId}/minimum-pipe-length-purchases/",
  NUT_SELECTIONS: "api/{companyId}/projects/{projectId}/nut-selections/",
  WASHER_SELECTIONS: "api/{companyId}/projects/{projectId}/washer-selections/",
  WELD_CLEARANCES: "api/{companyId}/projects/{projectId}/weld-clearances/",
  PIPING_POINT_USAGES:
    "api/{companyId}/projects/{projectId}/piping-point-usages/",
  REINFORCING_PADS: "api/{companyId}/projects/{projectId}/reinforcing-pads/",
  COMMODITY_CODES: "api/{companyId}/projects/{projectId}/commodity-codes/",
  MASTER_COMMODITY_CODE_ROWS:
    "api/{companyId}/projects/{projectId}/commodity-code-sheets/{sheetName}/rows/",
  OPERATORS: "api/{companyId}/projects/{projectId}/operators/",
  PARTS: "api/{companyId}/projects/{projectId}/parts/",
  PART_CONFIGURATIONS:
    "api/{companyId}/projects/{projectId}/parts/{partId}/part-configurations/",
  SPECIFICATIONS: "api/{companyId}/projects/{projectId}/specifications/",
  SPECIFICATION_VERSIONS:
    "api/{companyId}/projects/{projectId}/specifications/{specificationId}/versions/",
  SPECIFICATION_VERSION_IMAGES:
    "api/{companyId}/projects/{projectId}/specifications/{specificationId}/versions/{versionId}/images/",
  SPECIFICATION_VERSION_ETL_PROCESSES:
    "api/{companyId}/projects/{projectId}/specifications/{specificationId}/versions/{versionId}/etl-processes/",
  SPECIFICATION_VERSION_ETL_STEPS:
    "api/{companyId}/projects/{projectId}/specifications/{specificationId}/versions/{versionId}/etl-steps/",
  SPECIFICATION_VERSION_EXTRACTIONS:
    "api/{companyId}/projects/{projectId}/specifications/{specificationId}/versions/{versionId}/extractions/",
  SPECIFICATION_VERSION_BRANCH_TABLE_EXTRACTIONS:
    "api/{companyId}/projects/{projectId}/specifications/{specificationId}/versions/{versionId}/branch-table-extractions/",
  SPECIFICATION_VERSION_TRANFORMATIONS:
    "api/{companyId}/projects/{projectId}/specifications/{specificationId}/versions/{versionId}/transformations/",
  SPECIFICATION_VERSION_CATALOG_TRANFORMATIONS:
    "api/{companyId}/projects/{projectId}/specifications/{specificationId}/versions/{versionId}/catalog-transformations/",
  SPECIFICATION_VERSION_DOWNLOAD_EXCEL:
    "api/{companyId}/projects/{projectId}/specifications/{specificationId}/versions/{versionId}/download-specification-excel/",
  SPECIFICATION_VERSION_DOWNLOAD_CATALOG_EXCEL:
    "api/{companyId}/projects/{projectId}/specifications/{specificationId}/versions/{versionId}/download-catalog-excel/",
};

export default API_URLS;
