import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import {
  AppBar as MuiAppBar,
  Avatar,
  Badge,
  Box,
  CssBaseline,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/system";
import { Home } from "@mui/icons-material";

import { logout } from "../../redux/slices/authSlice";
import * as routeConfig from "../../routes/RouteConfig";
import { getInitials } from "../../utilities/utilities";

const drawerWidth = 180;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const TopBar = ({ user }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorElement, setAnchorElement] = useState(null);

  const onHandleMenu = (event) => {
    setAnchorElement(event.currentTarget);
  };

  const onHandleClose = () => {
    setAnchorElement(null);
  };

  const onHandleProfile = () => {
    onHandleClose();
    navigate(routeConfig.USER_SETTINGS);
  };

  const onHandleCompanySettings = () => {
    onHandleClose();
    navigate(routeConfig.COMPANY);
  };

  const onHandleLogout = () => {
    dispatch(logout());
    navigate(routeConfig.LOGIN);
  };

  const handleHome = () => {
    navigate(routeConfig.DASHBOARD);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="Home"
            onClick={handleHome}
            edge="start"
          >
            <Home />
          </IconButton>
          <Box sx={{ flexGrow: 1 }}></Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings" placement="bottom-start">
              <IconButton
                aria-label={getInitials(`${user.first_name} ${user.last_name}`)}
                aria-haspopup="true"
                onClick={onHandleMenu}
                sx={{ p: 0 }}
                size="small"
              >
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  variant="dot"
                >
                  <Avatar
                    src={user.avatar}
                    alt={getInitials(`${user.first_name} ${user.last_name}`)}
                  >
                    {getInitials(`${user.first_name} ${user.last_name}`)}
                  </Avatar>
                </StyledBadge>
              </IconButton>
            </Tooltip>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElement}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElement)}
              onClose={onHandleClose}
              sx={{ mt: "45px" }}
            >
              <MenuItem onClick={onHandleProfile}>Profile</MenuItem>
              <MenuItem onClick={onHandleCompanySettings}>
                Company Settings
              </MenuItem>
              <MenuItem onClick={onHandleLogout}>Logout</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default TopBar;
