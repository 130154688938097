import HttpService from "./httpService";
import API_URLS from "../constants/api";

class _APIService {
  /**
   * Login
   */
  loginUser = (credentials) => {
    return HttpService.post(API_URLS.LOGIN, credentials).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Refresh Token
   */
  refreshToken = (refreshToken) => {
    return HttpService.post(API_URLS.REFRESH_TOKEN, {
      refresh: refreshToken,
    }).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Reset Password
   */
  resetPassword = (data) => {
    return HttpService.post(API_URLS.RESET_PASSWORD, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Change Password
   */
  changePassword = (data) => {
    return HttpService.post(API_URLS.CHANGE_PASSWORD, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Send Recovery Link
   */
  sendRecoveryLink(data) {
    return HttpService.post(API_URLS.FORGOT_PASSWORD, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  }

  /**
   * Create User
   */
  postUser = (companyId, data) => {
    const URL = API_URLS.COMPANY_USERS.replace("{companyId}", companyId);
    return HttpService.post(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * List Users
   */
  getUsers = (companyId, params) => {
    const URL = API_URLS.COMPANY_USERS.replace("{companyId}", companyId);
    return HttpService.get(URL, params).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * User Details
   */
  getUser = (userId) => {
    const URL = API_URLS.USERS.replace("{userId}", userId);
    return HttpService.get(URL).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Company User Details
   */
  getCompanyUser = (companyId, userId) => {
    const URL = `${API_URLS.COMPANY_USERS.replace("{companyId}", companyId)}${userId}/`;
    return HttpService.get(URL).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Update User Details
   */
  putUser = (companyId, userId, data) => {
    const URL = `${API_URLS.COMPANY_USERS.replace("{companyId}", companyId)}${userId}/`;
    return HttpService.put(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Update User Details
   */
  patchUser = (companyId, userId, data) => {
    const URL = `${API_URLS.COMPANY_USERS.replace("{companyId}", companyId)}${userId}/`;
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    return HttpService.patch(URL, formData, {
      "Content-Type": "multipart/form-data",
    }).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Update Company Details
   */
  patchCompany = (companyId, data) => {
    const URL = API_URLS.COMPANY.replace("{companyId}", companyId);
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    return HttpService.patch(URL, formData, {
      "Content-Type": "multipart/form-data",
    }).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * List Projects
   */
  getProjects = (companyId, params) => {
    const URL = API_URLS.PROJECTS.replace("{companyId}", companyId);
    return HttpService.get(URL, params).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Get Project
   */
  getProject = (companyId, projectId) => {
    const URL = `${API_URLS.PROJECTS.replace("{companyId}", companyId)}${projectId}/`;
    return HttpService.get(URL).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Create Project
   */
  postProject = (companyId, data) => {
    const URL = API_URLS.PROJECTS.replace("{companyId}", companyId);
    return HttpService.post(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Patch Project
   */
  patchProject = (companyId, projectId, data) => {
    const URL = `${API_URLS.PROJECTS.replace(
      "{companyId}",
      companyId
    )}${projectId}/`;
    return HttpService.patch(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Put Project
   */
  putProject = (companyId, projectId, data) => {
    const URL = `${API_URLS.PROJECTS.replace(
      "{companyId}",
      companyId
    )}${projectId}/`;
    return HttpService.put(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * List Specifications
   */
  getSpecifications = (companyId, projectId, params) => {
    const URL = API_URLS.SPECIFICATIONS.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId);
    return HttpService.get(URL, params).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Get Specification
   */
  getSpecification = (companyId, projectId, specificationId) => {
    const URL = `${API_URLS.SPECIFICATIONS.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId)}${specificationId}/`;
    return HttpService.get(URL).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Create Specification
   */
  postSpecification = (companyId, projectId, data) => {
    const URL = API_URLS.SPECIFICATIONS.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId);
    return HttpService.post(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Create Specification Version
   */
  postSpecificationVersion = (companyId, projectId, specificationId, data) => {
    const URL = `${API_URLS.SPECIFICATION_VERSIONS.replace(
      "{companyId}",
      companyId
    )
      .replace("{projectId}", projectId)
      .replace("{specificationId}", specificationId)}`;
    const formData = new FormData();
    formData.append("version", data.version);
    formData.append("file", data.file);
    formData.append("company", companyId);
    return HttpService.post(URL, formData, {
      "Content-Type": "multipart/form-data",
    }).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Specification Version Actions
   */
  postSpecificationVersionAction = (
    companyId,
    projectId,
    specificationId,
    versionId,
    action,
    data = {}
  ) => {
    const URL = `${API_URLS.SPECIFICATION_VERSIONS.replace(
      "{companyId}",
      companyId
    )
      .replace("{projectId}", projectId)
      .replace("{specificationId}", specificationId)}${versionId}/`;
    return HttpService.post(URL, {
      action: action,
      ...data,
    }).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Update Specification Version
   */
  patchSpecificationVersion = (
    companyId,
    projectId,
    specificationId,
    versionId,
    data
  ) => {
    const URL = `${API_URLS.SPECIFICATION_VERSIONS.replace(
      "{companyId}",
      companyId
    )
      .replace("{projectId}", projectId)
      .replace("{specificationId}", specificationId)}${versionId}/`;
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    return HttpService.patch(URL, formData, {
      "Content-Type": "multipart/form-data",
    }).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Create Specification Version Image
   */
  postSpecificationVersionImage = (
    companyId,
    projectId,
    specificationId,
    versionId,
    data
  ) => {
    const URL = `${API_URLS.SPECIFICATION_VERSION_IMAGES.replace(
      "{companyId}",
      companyId
    )
      .replace("{projectId}", projectId)
      .replace("{specificationId}", specificationId)
      .replace("{versionId}", versionId)}`;
    const formData = new FormData();
    formData.append("original_image", data.image);
    return HttpService.post(URL, formData, {
      "Content-Type": "multipart/form-data",
    }).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Update Specification Version Image
   */
  patchSpecificationVersionImage = (
    companyId,
    projectId,
    specificationId,
    versionId,
    imageId,
    data
  ) => {
    const URL = `${API_URLS.SPECIFICATION_VERSION_IMAGES.replace(
      "{companyId}",
      companyId
    )
      .replace("{projectId}", projectId)
      .replace("{specificationId}", specificationId)
      .replace("{versionId}", versionId)}${imageId}/`;
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    return HttpService.patch(URL, formData, {
      "Content-Type": "multipart/form-data",
    }).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * List specification extractions
   */
  getSpecificationExtractions = (
    companyId,
    projectId,
    specificationId,
    versionId,
    hasBranchTable
  ) => {
    const extractionURL = hasBranchTable
      ? API_URLS.SPECIFICATION_VERSION_BRANCH_TABLE_EXTRACTIONS
      : API_URLS.SPECIFICATION_VERSION_EXTRACTIONS;
    const URL = extractionURL
      .replace("{companyId}", companyId)
      .replace("{projectId}", projectId)
      .replace("{specificationId}", specificationId)
      .replace("{versionId}", versionId);
    return HttpService.get(URL).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Extraction Actions
   */
  postExtractionAction = (
    companyId,
    projectId,
    specificationId,
    versionId,
    extractionId,
    action,
    data = {}
  ) => {
    const URL = `${API_URLS.SPECIFICATION_VERSION_EXTRACTIONS.replace(
      "{companyId}",
      companyId
    )
      .replace("{projectId}", projectId)
      .replace("{specificationId}", specificationId)
      .replace("{versionId}", versionId)}${extractionId}/`;
    return HttpService.post(URL, {
      action: action,
      ...data,
    }).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Extraction Actions
   */
  patchExtraction = (
    companyId,
    projectId,
    specificationId,
    versionId,
    extractionId,
    data
  ) => {
    const URL = `${API_URLS.SPECIFICATION_VERSION_EXTRACTIONS.replace(
      "{companyId}",
      companyId
    )
      .replace("{projectId}", projectId)
      .replace("{specificationId}", specificationId)
      .replace("{versionId}", versionId)}${extractionId}/`;
    return HttpService.patch(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Bulk Update Extractions
   */
  bulkUpdateExtraction = (
    companyId,
    projectId,
    specificationId,
    versionId,
    data
  ) => {
    const URL = `${API_URLS.SPECIFICATION_VERSION_EXTRACTIONS.replace(
      "{companyId}",
      companyId
    )
      .replace("{projectId}", projectId)
      .replace("{specificationId}", specificationId)
      .replace("{versionId}", versionId)}bulk-update/`;
    return HttpService.post(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Bulk Update Branch Table Extractions
   */
  bulkUpdateBranchTableExtraction = (
    companyId,
    projectId,
    specificationId,
    versionId,
    data
  ) => {
    const URL = `${API_URLS.SPECIFICATION_VERSION_BRANCH_TABLE_EXTRACTIONS.replace(
      "{companyId}",
      companyId
    )
      .replace("{projectId}", projectId)
      .replace("{specificationId}", specificationId)
      .replace("{versionId}", versionId)}bulk-update/`;
    return HttpService.post(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * List specification transformations
   */
  getSpecificationTransformations = (
    companyId,
    projectId,
    specificationId,
    versionId,
    type
  ) => {
    const transformationURL =
      type === "specification"
        ? API_URLS.SPECIFICATION_VERSION_TRANFORMATIONS
        : API_URLS.SPECIFICATION_VERSION_CATALOG_TRANFORMATIONS;
    const URL = transformationURL
      .replace("{companyId}", companyId)
      .replace("{projectId}", projectId)
      .replace("{specificationId}", specificationId)
      .replace("{versionId}", versionId);
    return HttpService.get(URL).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Transformation Actions
   */
  postTrasformationAction = (
    companyId,
    projectId,
    specificationId,
    versionId,
    transformationId,
    action,
    data = {}
  ) => {
    const URL = `${API_URLS.SPECIFICATION_VERSION_TRANFORMATIONS.replace(
      "{companyId}",
      companyId
    )
      .replace("{projectId}", projectId)
      .replace("{specificationId}", specificationId)
      .replace("{versionId}", versionId)}${transformationId}/`;
    return HttpService.post(URL, {
      action: action,
      ...data,
    }).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Bulk Update Specification Transformation
   */
  bulkUpdateSpecificationTransformation = (
    companyId,
    projectId,
    specificationId,
    versionId,
    data
  ) => {
    const URL = `${API_URLS.SPECIFICATION_VERSION_TRANFORMATIONS.replace(
      "{companyId}",
      companyId
    )
      .replace("{projectId}", projectId)
      .replace("{specificationId}", specificationId)
      .replace("{versionId}", versionId)}bulk-update/`;
    return HttpService.post(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Bulk Update Catalog Transformation
   */
  bulkUpdateCatalogTransformation = (
    companyId,
    projectId,
    specificationId,
    versionId,
    data
  ) => {
    const URL = `${API_URLS.SPECIFICATION_VERSION_CATALOG_TRANFORMATIONS.replace(
      "{companyId}",
      companyId
    )
      .replace("{projectId}", projectId)
      .replace("{specificationId}", specificationId)
      .replace("{versionId}", versionId)}bulk-update/`;
    return HttpService.post(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Update Specification Version ETL Process
   */
  patchSpecificationVersionETLProcess = (
    companyId,
    projectId,
    specificationId,
    versionId,
    processId,
    data
  ) => {
    const URL = `${API_URLS.SPECIFICATION_VERSION_ETL_PROCESSES.replace(
      "{companyId}",
      companyId
    )
      .replace("{projectId}", projectId)
      .replace("{specificationId}", specificationId)
      .replace("{versionId}", versionId)}${processId}/`;
    return HttpService.patch(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * ETL Process Actions
   */
  postSpecificationVersionETLProcessAction = (
    companyId,
    projectId,
    specificationId,
    versionId,
    processId,
    action,
    data = {}
  ) => {
    const URL = `${API_URLS.SPECIFICATION_VERSION_ETL_PROCESSES.replace(
      "{companyId}",
      companyId
    )
      .replace("{projectId}", projectId)
      .replace("{specificationId}", specificationId)
      .replace("{versionId}", versionId)}${processId}/`;
    return HttpService.post(URL, {
      action: action,
      ...data,
    }).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Get Specification
   */
  getSpecificationExtractionStep = (
    companyId,
    projectId,
    specificationId,
    versionId,
    stepId
  ) => {
    const URL = `${API_URLS.SPECIFICATION_VERSION_ETL_STEPS.replace(
      "{companyId}",
      companyId
    )
      .replace("{projectId}", projectId)
      .replace("{specificationId}", specificationId)
      .replace("{versionId}", versionId)}${stepId}/`;
    return HttpService.get(URL).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  getSpecificationExcelFile = (
    type,
    companyId,
    projectId,
    specificationId,
    versionId,
    params,
    additionalConfig = {}
  ) => {
    const excelFileURL =
      type === "specification"
        ? API_URLS.SPECIFICATION_VERSION_DOWNLOAD_EXCEL
        : API_URLS.SPECIFICATION_VERSION_DOWNLOAD_CATALOG_EXCEL;
    const URL = `${excelFileURL
      .replace("{companyId}", companyId)
      .replace("{projectId}", projectId)
      .replace("{specificationId}", specificationId)
      .replace("{versionId}", versionId)}`;
    return HttpService.getExcelFile(URL, params, additionalConfig).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  getAllCodeListExcelFile = (
    type,
    companyId,
    projectId,
    params,
    additionalConfig = {}
  ) => {
    const excelFileURL =
      type === "delta_all_code"
        ? API_URLS.ALL_CODE_LIST_DELTA
        : API_URLS.ALL_CODE_LIST_FULL;
    const URL = `${excelFileURL
      .replace("{companyId}", companyId)
      .replace("{projectId}", projectId)}`;
    return HttpService.getExcelFile(URL, params, additionalConfig).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * List Definitions
   */
  getDefinitions = (companyId, projectId, params) => {
    const URL = API_URLS.DEFINITIONS.replace("{companyId}", companyId).replace(
      "{projectId}",
      projectId
    );
    return HttpService.get(URL, params).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Get Definition
   */
  getDefinition = (companyId, projectId, definitionId) => {
    const URL = `${API_URLS.DEFINITIONS.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId)}${definitionId}/`;
    return HttpService.get(URL).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Create Definition
   */
  postDefinition = (companyId, projectId, data) => {
    const URL = API_URLS.DEFINITIONS.replace("{companyId}", companyId).replace(
      "{projectId}",
      projectId
    );
    return HttpService.post(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Patch Definition
   */
  patchDefinition = (companyId, projectId, definitionId, data) => {
    const URL = `${API_URLS.DEFINITIONS.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId)}${definitionId}/`;
    return HttpService.patch(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * List Short Codes
   */
  getShortCodes = (companyId, projectId, params) => {
    const URL = API_URLS.SHORT_CODES.replace("{companyId}", companyId).replace(
      "{projectId}",
      projectId
    );
    return HttpService.get(URL, params).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Get Short Code
   */
  getShortCode = (companyId, projectId, shortCodeId) => {
    const URL = `${API_URLS.SHORT_CODES.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId)}${shortCodeId}/`;
    return HttpService.get(URL).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Create Short Code
   */
  postShortCode = (companyId, projectId, data) => {
    const URL = API_URLS.SHORT_CODES.replace("{companyId}", companyId).replace(
      "{projectId}",
      projectId
    );
    return HttpService.post(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Patch Short Code
   */
  patchShortCode = (companyId, projectId, shortCodeId, data) => {
    const URL = `${API_URLS.SHORT_CODES.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId)}${shortCodeId}/`;
    return HttpService.patch(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * List Direction Changes
   */
  getDirectionChanges = (companyId, projectId, params) => {
    const URL = API_URLS.DIRECTION_CHANGES.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId);
    return HttpService.get(URL, params).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Get Direction Change
   */
  getDirectionChange = (companyId, projectId, directionChangeId) => {
    const URL = `${API_URLS.DIRECTION_CHANGES.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId)}${directionChangeId}/`;
    return HttpService.get(URL).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Create Direction Change
   */
  postDirectionChange = (companyId, projectId, data) => {
    const URL = API_URLS.DIRECTION_CHANGES.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId);
    return HttpService.post(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Patch Direction Change
   */
  patchDirectionChange = (companyId, projectId, directionChangeId, data) => {
    const URL = `${API_URLS.DIRECTION_CHANGES.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId)}${directionChangeId}/`;
    return HttpService.patch(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * List Pipe Thicknesses
   */
  getPipeThicknesses = (companyId, projectId, params) => {
    const URL = API_URLS.PIPE_THICKNESSES.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId);
    return HttpService.get(URL, params).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Get Pipe Thickness
   */
  getPipeThickness = (companyId, projectId, pipeThicknessId) => {
    const URL = `${API_URLS.PIPE_THICKNESSES.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId)}${pipeThicknessId}/`;
    return HttpService.get(URL).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Create Pipe Thickness
   */
  postPipeThickness = (companyId, projectId, data) => {
    const URL = API_URLS.PIPE_THICKNESSES.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId);
    return HttpService.post(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Patch Pipe Thickness
   */
  patchPipeThickness = (companyId, projectId, pipeThicknessId, data) => {
    const URL = `${API_URLS.PIPE_THICKNESSES.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId)}${pipeThicknessId}/`;
    return HttpService.patch(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * List Minimum Pipe Length Rules
   */
  getMinimumPipeLengthRules = (companyId, projectId, params) => {
    const URL = API_URLS.MINIMUM_PIPE_LENGTH_RULES.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId);
    return HttpService.get(URL, params).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Get Minimum Pipe Length Rule
   */
  getMinimumPipeLengthRule = (
    companyId,
    projectId,
    minimumPipeLengthRuleId
  ) => {
    const URL = `${API_URLS.MINIMUM_PIPE_LENGTH_RULES.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId)}${minimumPipeLengthRuleId}/`;
    return HttpService.get(URL).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Create Minimum Pipe Length Rule
   */
  postMinimumPipeLengthRule = (companyId, projectId, data) => {
    const URL = API_URLS.MINIMUM_PIPE_LENGTH_RULES.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId);
    return HttpService.post(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Patch Minimum Pipe Length Rule
   */
  patchMinimumPipeLengthRule = (
    companyId,
    projectId,
    minimumPipeLengthRuleId,
    data
  ) => {
    const URL = `${API_URLS.MINIMUM_PIPE_LENGTH_RULES.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId)}${minimumPipeLengthRuleId}/`;
    return HttpService.patch(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * List Minimum Pipe Length Purchases
   */
  getMinimumPipeLengthPurchases = (companyId, projectId, params) => {
    const URL = API_URLS.MINIMUM_PIPE_LENGTH_PURCHASES.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId);
    return HttpService.get(URL, params).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Get Minimum Pipe Length Purchase
   */
  getMinimumPipeLengthPurchase = (
    companyId,
    projectId,
    minimumPipeLengthPurchaseId
  ) => {
    const URL = `${API_URLS.MINIMUM_PIPE_LENGTH_PURCHASES.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId)}${minimumPipeLengthPurchaseId}/`;
    return HttpService.get(URL).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Create Minimum Pipe Length Purchase
   */
  postMinimumPipeLengthPurchase = (companyId, projectId, data) => {
    const URL = API_URLS.MINIMUM_PIPE_LENGTH_PURCHASES.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId);
    return HttpService.post(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Patch Minimum Pipe Length Purchase
   */
  patchMinimumPipeLengthPurchase = (
    companyId,
    projectId,
    minimumPipeLengthPurchaseId,
    data
  ) => {
    const URL = `${API_URLS.MINIMUM_PIPE_LENGTH_PURCHASES.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId)}${minimumPipeLengthPurchaseId}/`;
    return HttpService.patch(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * List Nut Selections
   */
  getNutSelections = (companyId, projectId, params) => {
    const URL = API_URLS.NUT_SELECTIONS.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId);
    return HttpService.get(URL, params).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Get Nut Selection
   */
  getNutSelection = (companyId, projectId, nutSelectionId) => {
    const URL = `${API_URLS.NUT_SELECTIONS.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId)}${nutSelectionId}/`;
    return HttpService.get(URL).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Create Nut Selection
   */
  postNutSelection = (companyId, projectId, data) => {
    const URL = API_URLS.NUT_SELECTIONS.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId);
    return HttpService.post(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Patch Nut Selection
   */
  patchNutSelection = (companyId, projectId, nutSelectionId, data) => {
    const URL = `${API_URLS.NUT_SELECTIONS.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId)}${nutSelectionId}/`;
    return HttpService.patch(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * List Washer Selections
   */
  getWasherSelections = (companyId, projectId, params) => {
    const URL = API_URLS.WASHER_SELECTIONS.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId);
    return HttpService.get(URL, params).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Get Washer Selection
   */
  getWasherSelection = (companyId, projectId, washerSelectionId) => {
    const URL = `${API_URLS.WASHER_SELECTIONS.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId)}${washerSelectionId}/`;
    return HttpService.get(URL).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Create Washer Selection
   */
  postWasherSelection = (companyId, projectId, data) => {
    const URL = API_URLS.WASHER_SELECTIONS.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId);
    return HttpService.post(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Patch Washer Selection
   */
  patchWasherSelection = (companyId, projectId, washerSelectionId, data) => {
    const URL = `${API_URLS.WASHER_SELECTIONS.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId)}${washerSelectionId}/`;
    return HttpService.patch(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * List Weld Clearances
   */
  getWeldClearances = (companyId, projectId, params) => {
    const URL = API_URLS.WELD_CLEARANCES.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId);
    return HttpService.get(URL, params).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Get Weld Clearance
   */
  getWeldClearance = (companyId, projectId, weldClearanceId) => {
    const URL = `${API_URLS.WELD_CLEARANCES.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId)}${weldClearanceId}/`;
    return HttpService.get(URL).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Create Weld Clearance
   */
  postWeldClearance = (companyId, projectId, data) => {
    const URL = API_URLS.WELD_CLEARANCES.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId);
    return HttpService.post(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Patch Weld Clearance
   */
  patchWeldClearance = (companyId, projectId, weldClearanceId, data) => {
    const URL = `${API_URLS.WELD_CLEARANCES.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId)}${weldClearanceId}/`;
    return HttpService.patch(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * List Piping poit usage with flow directions
   */
  getPPUFDs = (companyId, projectId, params) => {
    const URL = API_URLS.PIPING_POINT_USAGES.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId);
    return HttpService.get(URL, params).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Get Piping poit usage with flow direction
   */
  getPPUFD = (companyId, projectId, ppuFDId) => {
    const URL = `${API_URLS.PIPING_POINT_USAGES.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId)}${ppuFDId}/`;
    return HttpService.get(URL).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Create Piping poit usage with flow direction
   */
  postPPUFD = (companyId, projectId, data) => {
    const URL = API_URLS.PIPING_POINT_USAGES.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId);
    return HttpService.post(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Patch Piping poit usage with flow direction
   */
  patchPPUFD = (companyId, projectId, ppuFDId, data) => {
    const URL = `${API_URLS.PIPING_POINT_USAGES.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId)}${ppuFDId}/`;
    return HttpService.patch(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * List Reinforcing Pads
   */
  getReinforcingPads = (companyId, projectId, params) => {
    const URL = API_URLS.REINFORCING_PADS.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId);
    return HttpService.get(URL, params).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Get Reinforcing Pad
   */
  getReinforcingPad = (companyId, projectId, reinforcingPadId) => {
    const URL = `${API_URLS.REINFORCING_PADS.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId)}${reinforcingPadId}/`;
    return HttpService.get(URL).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Create Reinforcing Pad
   */
  postReinforcingPad = (companyId, projectId, data) => {
    const URL = API_URLS.REINFORCING_PADS.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId);
    return HttpService.post(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Patch Reinforcing Pad
   */
  patchReinforcingPad = (companyId, projectId, reinforcingPadId, data) => {
    const URL = `${API_URLS.REINFORCING_PADS.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId)}${reinforcingPadId}/`;
    return HttpService.patch(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * List Parts
   */
  getParts = (companyId, projectId, params) => {
    const URL = API_URLS.PARTS.replace("{companyId}", companyId).replace(
      "{projectId}",
      projectId
    );
    return HttpService.get(URL, params).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * List Part Configurations
   */
  getPartConfigurations = (companyId, projectId, partId, params) => {
    const URL = API_URLS.PART_CONFIGURATIONS.replace("{companyId}", companyId)
      .replace("{projectId}", projectId)
      .replace("{partId}", partId);
    return HttpService.get(URL, params).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Get Part Configuration
   */
  getPartConfiguration = (
    companyId,
    projectId,
    partId,
    partConfigurationId
  ) => {
    const URL = `${API_URLS.PART_CONFIGURATIONS.replace(
      "{companyId}",
      companyId
    )
      .replace("{projectId}", projectId)
      .replace("{partId}", partId)}${partConfigurationId}/`;
    return HttpService.get(URL).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Create Part Configuration
   */
  postPartConfiguration = (companyId, projectId, partId, data) => {
    const URL = API_URLS.PART_CONFIGURATIONS.replace("{companyId}", companyId)
      .replace("{projectId}", projectId)
      .replace("{partId}", partId);
    return HttpService.post(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Patch Part Configuration
   */
  patchPartConfiguration = (
    companyId,
    projectId,
    partId,
    partConfigurationId,
    data
  ) => {
    const URL = `${API_URLS.PART_CONFIGURATIONS.replace(
      "{companyId}",
      companyId
    )
      .replace("{projectId}", projectId)
      .replace("{partId}", partId)}${partConfigurationId}/`;
    return HttpService.patch(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * List Commodity Codes
   */
  getCommodityCodes = (companyId, projectId, params) => {
    const URL = API_URLS.COMMODITY_CODES.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId);
    return HttpService.get(URL, params).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Get Commodity Code
   */
  getCommodityCode = (companyId, projectId, commodityCodeId) => {
    const URL = `${API_URLS.COMMODITY_CODES.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId)}${commodityCodeId}/`;
    return HttpService.get(URL).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Create Commodity Code
   */
  postCommodityCode = (companyId, projectId, data) => {
    const URL = API_URLS.COMMODITY_CODES.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId);
    return HttpService.post(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * Patch Commodity Code
   */
  patchCommodityCode = (companyId, projectId, commodityCodeId, data) => {
    const URL = `${API_URLS.COMMODITY_CODES.replace(
      "{companyId}",
      companyId
    ).replace("{projectId}", projectId)}${commodityCodeId}/`;
    return HttpService.patch(URL, data).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };

  /**
   * List Master Commodity Code Rows
   */
  getMasterCommodityCodeRows = (companyId, projectId, sheetName, params) => {
    const URL = API_URLS.MASTER_COMMODITY_CODE_ROWS.replace(
      "{companyId}",
      companyId
    )
      .replace("{projectId}", projectId)
      .replace("{sheetName}", sheetName);
    return HttpService.get(URL, params).then(
      (res) => res.data,
      (err) => Promise.reject(err)
    );
  };
}

const APIService = new _APIService();
export default APIService;
