import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useOutlet, useLocation } from "react-router-dom";
import { Box, Drawer } from "@mui/material";
import { styled } from "@mui/system";
import { updateSnackbar } from "../../redux/slices/snackbarSlice";
import TopBar from "./TopBar";
import NavBar from "./NavBar";
import CustomSnackbar from "./CustomSnackbar";

const Root = styled("div")({
  display: "flex",
});

const Main = styled("main")(({ theme }) => ({
  flex: "1 1 auto",
  display: "flex",
  overflow: "hidden",
  padding: theme.spacing(3),
  paddingTop: theme.spacing(16),
}));

const RightDrawer = styled(Drawer)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 2,
}));

const DashboardLayout = ({ path, ...props }) => {
  const outlet = useOutlet();
  const dispatch = useDispatch();
  const location = useLocation();
  const [selectedPath, setSelectedPath] = useState(location.pathname);
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const { isAuthenticated, user, company } = useSelector((state) => state.auth);
  const { snackbar, message, type, autoHideDuration } = useSelector(
    (state) => state.snackbar
  );
  const { open, content } = useSelector((state) => state.drawer);

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  const handleSnackbarClose = () => {
    dispatch(
      updateSnackbar({
        snackbar: false,
        type: "",
        message: "",
      })
    );
  };

  return (
    <Root>
      <CustomSnackbar
        onClose={handleSnackbarClose}
        open={snackbar}
        message={message}
        type={type}
        autoHideDuration={autoHideDuration}
      />
      <TopBar
        isMobileNavOpen={isMobileNavOpen}
        setMobileNavOpen={setMobileNavOpen}
        user={user}
        company={company}
        setSelectedPath={setSelectedPath}
      />
      <NavBar
        isMobileNavOpen={isMobileNavOpen}
        setMobileNavOpen={setMobileNavOpen}
        user={user}
        company={company}
        selectedPath={selectedPath}
        setSelectedPath={setSelectedPath}
      />
      <Main>{outlet}</Main>
      <RightDrawer
        anchor="right"
        open={open}
        PaperProps={{
          sx: { width: 400, bgcolor: "#F9FAFC" },
        }}
        variant="temporary"
      >
        <Box p={2}>{content}</Box>
      </RightDrawer>
    </Root>
  );
};

export default DashboardLayout;
