import React, { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { useDispatch } from "react-redux";
import APIService from "./services/apiService";
import {
  refreshToken,
  updateCompany,
  updateUser,
} from "./redux/slices/authSlice";
import HttpService from "./services/httpService";
import Error500Modal from "./components/Error500Modal";
import AppRoutes from "./routes/AppRoutes";
import { Box, CircularProgress } from "@mui/material";

function App() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    HttpService.setAPIDomain();
    HttpService.setDispatcher(dispatch);
    const initializeApp = async () => {
      try {
        const refreshResponse = await dispatch(refreshToken()).unwrap();
        if (refreshResponse !== null) {
          const decodedToken = jwtDecode(refreshResponse.access);
          const userId = decodedToken.user_id;

          const userResponse = await APIService.getUser(userId);
          dispatch(updateUser(userResponse));
          dispatch(updateCompany(userResponse.company));
        }
      } catch (err) {
        console.error("Initialization error:", err);
      } finally {
        setLoading(false); // Ensure we stop loading regardless of success or failure
      }
    };

    initializeApp();
  }, [dispatch]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <AppRoutes />
      <Error500Modal />
    </>
  );
}

export default App;
