export const ERROR_401 = "/errors/error-401";
export const ERROR_404 = "/errors/error-404";
export const ERROR_500 = "/errors/error-500";

export const LOGIN = "/login";
export const FORGOT_PASSWORD = "/forgot-password";
export const RESET_PASSWORD = "/reset-password/";

export const BASE = "/";
export const ADD = "add";
export const EDIT = "edit";
export const DASHBOARD = "dashboard";
export const USER_SETTINGS = "settings";
export const COMPANY = "company";
export const CODE = "code";
export const CODES = "codes";
export const COMMODITY_CODE = "commodity-code";
export const COMMODITY_CODES = "commodity-codes";
export const COMBINATION = "combination";
export const COMBINATIONS = "combinations";
export const CONFIGURATION = "configuration";
export const CONFIGURATIONS = "configurations";
export const ENGINEERINGITEM = "engineeringitem";
export const ENGINEERINGITEMS = "engineeringitems";
export const OPERATOR = "operator";
export const OPERATORS = "operators";
export const PROJECT = "project";
export const PROJECTS = "projects";
export const SPECIFICATION = "specification";
export const SPECIFICATIONS = "specifications";
export const USER = "user";
export const USERS = "users";
