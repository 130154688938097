import axios from "axios";
import AuthTokenService from "./authTokenService";
import { store } from "../redux/store";
import { refreshToken, logout } from "../redux/slices/authSlice";

const API_PREFIX_MAP = {
  LOCAL: "http://localhost:8000",
  DEVELOP: "https://dev-api.specgenie.ai/",
};
class _HttpService {
  _apiDomain = API_PREFIX_MAP.DEVELOP;
  _companyId = null;

  dispatchAction = null;

  setAPIDomain() {
    this._apiDomain = API_PREFIX_MAP.DEVELOP;
  }

  setDispatcher(dispatchAction) {
    this.dispatchAction = dispatchAction;
  }

  getCompanyId() {
    return this._companyId;
  }

  setCompanyId(companyId) {
    this._companyId = companyId;
  }

  httpHeaders(extra_headers) {
    return {
      ...AuthTokenService.getAuthHeader(),
      ...extra_headers,
    };
  }

  prepareUrl(url) {
    return `${this._apiDomain}/${url}`;
  }

  get(url, params) {
    const reqConfig = {
      method: "get",
      url: this.prepareUrl(url),
      headers: this.httpHeaders(),
      params,
    };
    return this.processRequest(reqConfig);
  }

  getFile(url) {
    const reqConfig = {
      method: "get",
      url,
      headers: this.httpHeaders(),
      responseType: "blob",
    };
    return this.processRequest(reqConfig);
  }

  getExcelFile(url, params, additionalConfig = {}) {
    const reqConfig = {
      method: "get",
      url: this.prepareUrl(url),
      params,
      headers: { ...this.httpHeaders(), "Content-type": "application/json" },
      responseType: "arraybuffer",
      Accept:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ...additionalConfig,
    };
    return this.processRequest(reqConfig);
  }

  getHtml(url) {
    const reqConfig = {
      method: "get",
      headers: this.httpHeaders(),
      url,
    };
    return this.processRequest(reqConfig);
  }

  post(url, data = {}, extra_headers = {}, params = {}, others = {}) {
    const reqConfig = {
      method: "post",
      url: this.prepareUrl(url),
      headers: this.httpHeaders(extra_headers),
      params,
      data,
      ...others,
    };
    return this.processRequest(reqConfig);
  }

  patch(url, data = {}, extra_headers = {}, params = {}) {
    const reqConfig = {
      method: "patch",
      url: this.prepareUrl(url),
      headers: this.httpHeaders(extra_headers),
      params,
      data,
    };
    return this.processRequest(reqConfig);
  }

  put(url, data = {}, extra_headers = {}, params = {}) {
    const reqConfig = {
      method: "put",
      url: this.prepareUrl(url),
      headers: this.httpHeaders(extra_headers),
      params,
      data,
    };
    return this.processRequest(reqConfig);
  }

  delete(url, data = {}, params = {}) {
    const reqConfig = {
      method: "delete",
      url: this.prepareUrl(url),
      headers: this.httpHeaders(),
      params,
      data,
    };
    return this.processRequest(reqConfig);
  }

  fileUpload(url, method, data = {}, params = {}) {
    const headers = this.httpHeaders();
    headers["Content-Type"] = "multipart/form-data";
    const reqConfig = {
      method: method,
      url: this.prepareUrl(url),
      headers,
      params,
      data,
    };
    return this.processRequest(reqConfig);
  }

  async processRequest(reqConfig) {
    try {
      const response = await axios(reqConfig);
      if (response?.status && response.status >= 200 && response.status < 300) {
        return response;
      }
      throw new Error(response.statusText);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Token might be expired, try to refresh
        try {
          const refreshResult = await store.dispatch(refreshToken());
          if (refreshToken.fulfilled.match(refreshResult)) {
            // Token refreshed successfully, update the Authorization header and retry the original request
            const newToken = AuthTokenService.getAuthToken();
            reqConfig.headers.Authorization = `Bearer ${newToken}`;
            return this.processRequest(reqConfig); // Retry the original request
          } else {
            // Refresh failed, logout the user
            store.dispatch(logout());
            throw new Error("Session expired. Please log in again.");
          }
        } catch (refreshError) {
          console.error("Token refresh failed:", refreshError);
          store.dispatch(logout());
          throw new Error("Session expired. Please log in again.");
        }
      }

      console.error("API request error:", error);
      return Promise.reject(error.response || error);
    }
  }

  handleUnauthorized(reqConfig) {
    // calling this method to clear authToken from service and local storage
    AuthTokenService.handleLogoutSuccess();
    console.log("reqConfig", reqConfig.url.includes("/api/login/"));
    if (!reqConfig.url.includes("/api/login/")) {
      // Re-initializing entire app
      window.location = "/login";
    }
  }
}

const HttpService = new _HttpService();
export default HttpService;
