import React from "react";
import { Alert, Button, Snackbar } from "@mui/material";

const CustomSnackbar = (props) => {
  const {
    open,
    onClose,
    message,
    type,
    actionText,
    onAction,
    autoHideDuration,
  } = props;

  return (
    <Snackbar
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      open={open}
      action={
        actionText &&
        onAction && (
          <Button color="inherit" size="small" onClick={onAction}>
            {actionText}
          </Button>
        )
      }
    >
      <Alert
        onClose={onClose}
        severity={type}
        variant="filled"
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
