import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import lazyLoad from "../utilities/lazy-load.hoc";

import AuthLayout from "../layouts/auth/Auth";
import DashboardLayout from "../layouts/dashboard/Dashboard";
import MainLayout from "../layouts/main/Main";
import ErrorLayout from "../layouts/error/Error";
import * as routeConfig from "./RouteConfig";
import { useSelector } from "react-redux";

const LoginComponent = React.lazy(
  () => import(/* webpackChunkName: "login-page" */ "../pages/auth/Login")
);

const ForgotPasswordComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "forgot-password-page" */ "../pages/auth/ForgotPassword"
    )
);

const ResetPasswordComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "reset-password-page" */ "../pages/auth/ResetPassword"
    )
);

const DashboardComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "dashboard-page" */ "../pages/dashboard/Dashboard"
    )
);

const SettingsComponent = React.lazy(
  () =>
    import(/* webpackChunkName: "settings-page" */ "../pages/settings/Settings")
);

const CommodityCodesComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "commodity-codes-page" */ "../pages/commodity-codes/CommodityCodes"
    )
);

const CombinationsComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "combinations-page" */ "../pages/combinations/combinations"
    )
);

const CompanyComponent = React.lazy(
  () =>
    import(/* webpackChunkName: "company-page" */ "../pages/company/company")
);

const ConfigurationsComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "configurations-page" */ "../pages/configurations/configurations"
    )
);

const Error401Component = React.lazy(
  () =>
    import(
      /* webpackChunkName: "error-401-page" */ "../pages/error401/Error401"
    )
);

const Error404Component = React.lazy(
  () =>
    import(
      /* webpackChunkName: "error-404-page" */ "../pages/error404/Error404"
    )
);

const Error500Component = React.lazy(
  () =>
    import(
      /* webpackChunkName: "error-500-page" */ "../pages/error500/Error500"
    )
);

const SpecificationsComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "specifications-page" */ "../pages/specifications/Specifications"
    )
);

const SpecificationComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "specification-page" */ "../pages/specification/Specification"
    )
);

const LoginLazyComponent = lazyLoad(LoginComponent);
const ForgotPasswordLazyComponent = lazyLoad(ForgotPasswordComponent);
const ResetPasswordLazyComponent = lazyLoad(ResetPasswordComponent);
const DashboardLazyComponent = lazyLoad(DashboardComponent);
const SettingsLazyComponent = lazyLoad(SettingsComponent);
const CommodityCodesLazyComponent = lazyLoad(CommodityCodesComponent);
const CombinationsLazyComponent = lazyLoad(CombinationsComponent);
const CompanyLazyComponent = lazyLoad(CompanyComponent);
const ConfigurationsLazyComponent = lazyLoad(ConfigurationsComponent);
const Error401LazyComponent = lazyLoad(Error401Component);
const Error404LazyComponent = lazyLoad(Error404Component);
const Error500LazyComponent = lazyLoad(Error500Component);
const SpecificationsLazyComponent = lazyLoad(SpecificationsComponent);
const SpecificationLazyComponent = lazyLoad(SpecificationComponent);

const AppRoutes = () => {
  const { company } = useSelector((state) => state.auth);
  const isOnboardingDone = company?.has_projects || false;
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path="/" element={<Navigate to={routeConfig.LOGIN} />} />
          <Route path={routeConfig.LOGIN} element={<LoginLazyComponent />} />
          <Route
            path={routeConfig.FORGOT_PASSWORD}
            element={<ForgotPasswordLazyComponent />}
          />
          <Route
            path={`${routeConfig.RESET_PASSWORD}:uuid`}
            element={<ResetPasswordLazyComponent />}
          />
        </Route>
        <Route
          element={isOnboardingDone ? <DashboardLayout /> : <MainLayout />}
        >
          <Route
            path={routeConfig.DASHBOARD}
            element={<DashboardLazyComponent />}
          />
          <Route
            path={routeConfig.COMPANY}
            element={<CompanyLazyComponent />}
          />
          <Route
            path={routeConfig.USER_SETTINGS}
            element={<SettingsLazyComponent />}
          />
          <Route
            path={routeConfig.COMMODITY_CODES}
            element={<CommodityCodesLazyComponent />}
          />
          <Route
            path={routeConfig.COMBINATIONS}
            element={<CombinationsLazyComponent />}
          />
          <Route
            path={routeConfig.CONFIGURATIONS}
            element={<ConfigurationsLazyComponent />}
          />
          <Route
            path={routeConfig.SPECIFICATIONS}
            element={<SpecificationsLazyComponent />}
          />
          <Route
            path={`/${routeConfig.SPECIFICATION}/:specificationId/`}
            element={<SpecificationLazyComponent />}
          />
        </Route>
        <Route element={<ErrorLayout />}>
          <Route path="/errors" />
          <Route
            path={routeConfig.ERROR_401}
            element={<Error401LazyComponent />}
          />
          <Route
            path={routeConfig.ERROR_404}
            element={<Error404LazyComponent />}
          />
          <Route
            path={routeConfig.ERROR_500}
            element={<Error500LazyComponent />}
          />
        </Route>
        <Route
          path="*"
          element={<Navigate to={routeConfig.ERROR_404} replace />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
