import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
  content: null, // React component to render inside the drawer
};

const drawerSlice = createSlice({
  name: "drawer",
  initialState,
  reducers: {
    openDrawer(state, action) {
      state.open = true;
      state.content = action.payload; // The React component passed to render
    },
    closeDrawer(state) {
      state.open = false;
      state.content = null;
    },
  },
});

export const { openDrawer, closeDrawer } = drawerSlice.actions;
export default drawerSlice.reducer;
