import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import {
  Drawer as MuiDrawer,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
  Tooltip,
  Avatar,
} from "@mui/material";
import { ChevronRight, ChevronLeft } from "@mui/icons-material";
import { styled } from "@mui/system";

import NavBarConfig from "./NavBarConfig";

const drawerWidth = 180;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  variants: [
    {
      props: ({ open }) => open,
      style: {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
      },
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
      },
    },
  ],
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const NavBar = ({
  isMobileNavOpen,
  setMobileNavOpen,
  user,
  company,
  selectedPath,
  setSelectedPath,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleDrawerClose = () => {
    setMobileNavOpen(false);
  };

  const handleNavItem = (href) => {
    setSelectedPath(href);
    navigate(href);
  };

  const isTabActive = (href) => {
    if (selectedPath === href || selectedPath.includes(href)) {
      return true;
    }
    return false;
  };

  const navbarContent = (
    <List>
      {NavBarConfig[user.user_type] &&
        NavBarConfig[user.user_type].map(
          (item, index) =>
            !item.hidden && (
              <ListItem
                key={item.text}
                disablePadding
                sx={{ display: "block" }}
              >
                <ListItemButton
                  sx={[
                    {
                      minHeight: 48,
                      px: 2.5,
                    },
                    isMobileNavOpen
                      ? {
                          justifyContent: "initial",
                        }
                      : {
                          justifyContent: "center",
                        },
                  ]}
                  key={item.text}
                  onClick={() => {
                    handleNavItem(item.href);
                  }}
                  selected={isTabActive(item.href)}
                >
                  {item.icon && (
                    <Tooltip title={item.text} placement="right-start">
                      <ListItemIcon
                        sx={[
                          {
                            minWidth: 0,
                            justifyContent: "center",
                          },
                          isMobileNavOpen
                            ? {
                                mr: 3,
                              }
                            : {
                                mr: "auto",
                              },
                        ]}
                      >
                        {item.icon}
                      </ListItemIcon>
                    </Tooltip>
                  )}
                  <ListItemText
                    primary={item.text}
                    sx={[
                      isMobileNavOpen
                        ? {
                            opacity: 1,
                          }
                        : {
                            opacity: 0,
                          },
                    ]}
                  />
                </ListItemButton>
              </ListItem>
            )
        )}
    </List>
  );

  return (
    <Drawer variant="permanent" open={isMobileNavOpen}>
      <DrawerHeader>
        <Avatar
          variant="square"
          src={company.logo}
          alt={company.name}
          sx={{ width: "75%" }}
        />
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "rtl" ? <ChevronRight /> : <ChevronLeft />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      {navbarContent}
    </Drawer>
  );
};

NavBar.propTypes = {
  setMobileNavOpen: PropTypes.func.isRequired,
  isMobileNavOpen: PropTypes.bool.isRequired,
};

export default NavBar;
