import React from "react";
import {
  // Class,
  // Code,
  ClosedCaption,
  Dashboard,
  Description,
  // Engineering,
  JoinFull,
  // People,
  // Plumbing,
  // Settings,
  SettingsSuggest,
} from "@mui/icons-material";

import * as routeConfig from "../../routes/RouteConfig";

const navBarConfig = {
  company_admin: [
    {
      icon: <Dashboard />,
      text: "Overview",
      href: routeConfig.DASHBOARD,
    },
    {
      icon: <Description />,
      text: "Specifications",
      href: routeConfig.SPECIFICATIONS,
    },
    // {
    //   icon: <Code />,
    //   text: "Codes",
    //   href: routeConfig.CODES,
    // },
    {
      icon: <SettingsSuggest />,
      text: "Configurations",
      href: routeConfig.CONFIGURATIONS,
    },
    {
      icon: <JoinFull />,
      text: "Size Combinations",
      href: routeConfig.COMBINATIONS,
    },
    {
      icon: <ClosedCaption />,
      text: "Commodity Codes",
      href: routeConfig.COMMODITY_CODES,
    },
    // {
    //   icon: <Engineering />,
    //   text: "Engineering Items",
    //   href: routeConfig.ENGINEERINGITEMS,
    // },
    // {
    //   icon: <Plumbing />,
    //   text: "Operators",
    //   href: routeConfig.OPERATORS,
    // },
  ],
  material_engineer: [
    {
      icon: <Dashboard />,
      text: "Overview",
      href: routeConfig.DASHBOARD,
    },
    {
      icon: <Description />,
      text: "Specifications",
      href: routeConfig.SPECIFICATIONS,
    },
    // {
    //   icon: <Code />,
    //   text: "Codes",
    //   href: routeConfig.CODES,
    // },
    {
      icon: <SettingsSuggest />,
      text: "Configurations",
      href: routeConfig.CONFIGURATIONS,
    },
    {
      icon: <JoinFull />,
      text: "Size Combinations",
      href: routeConfig.COMBINATIONS,
    },
    {
      icon: <ClosedCaption />,
      text: "Commodity Codes",
      href: routeConfig.COMMODITY_CODES,
    },
    // {
    //   icon: <Engineering />,
    //   text: "Engineering Items",
    //   href: routeConfig.ENGINEERINGITEMS,
    // },
    // {
    //   icon: <Plumbing />,
    //   text: "Operators",
    //   href: routeConfig.OPERATORS,
    // },
  ],
  design_engineer: [
    {
      icon: <Dashboard />,
      text: "Overview",
      href: routeConfig.DASHBOARD,
    },
    {
      icon: <Description />,
      text: "Specifications",
      href: routeConfig.SPECIFICATIONS,
    },
  ],
};

export default navBarConfig;
