import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  AppBar as MuiAppBar,
  Avatar,
  Badge,
  Box,
  CssBaseline,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/system";
import { Add, Check, Close } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";

import { logout } from "../../redux/slices/authSlice";
import { updateSnackbar } from "../../redux/slices/snackbarSlice";
import {
  setProjects,
  setProjectCount,
  setSelectedProject,
} from "../../redux/slices/appSlice";
import * as routeConfig from "../../routes/RouteConfig";
import APIService from "../../services/apiService";
import PersistentStorageService from "../../services/persistentStorageService";
import { getInitials } from "../../utilities/utilities";

import NavBarConfig from "./NavBarConfig";

const drawerWidth = 180;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const TopBar = ({
  isMobileNavOpen,
  setMobileNavOpen,
  user,
  company,
  setSelectedPath,
}) => {
  const { count, projects } = useSelector((state) => state.app);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorElement, setAnchorElement] = useState(null);
  const [userSelectedProject, setUserSelectedProject] = useState(
    projects.length > 0 ? projects[0].id : ""
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [newOptionText, setNewOptionText] = useState("");

  const menuProps = {
    PaperProps: {
      onClick: (e) => isAddingNew && e.stopPropagation(),
    },
  };

  useEffect(() => {
    APIService.getProjects(company.id).then(
      (response) => {
        dispatch(setProjects(response.results));
        dispatch(setProjectCount(response.results.length));
        const project = PersistentStorageService.getSelectedProject();
        if (project) {
          setUserSelectedProject(project.id);
          dispatch(setSelectedProject(project));
        } else if (response.results.length > 0) {
          setUserSelectedProject(response.results[0].id);
          dispatch(setSelectedProject(response.results[0]));
        } else {
          setUserSelectedProject([]);
          dispatch(setSelectedProject([]));
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }, [company.id, count, dispatch]);

  const handleProjectChange = (event) => {
    if (event.target.value !== undefined) {
      const selectedValue = event.target.value;
      setUserSelectedProject(selectedValue);
      const project = projects.find((project) => project.id === selectedValue);
      dispatch(setSelectedProject(project));
      PersistentStorageService.persistSelectedProject(project);
      setIsDropdownOpen(false);
      setSelectedPath(NavBarConfig[user.user_type][0].href);
      navigate(NavBarConfig[user.user_type][0].href);
    }
  };

  const handleAddNewClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsAddingNew(true);
  };

  const resetAddNew = () => {
    setIsAddingNew(false);
    setNewOptionText("");
  };

  const handleKeyDown = (event) => {
    event.stopPropagation();
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveNewOption(event);
    } else if (event.key === "Escape") {
      resetAddNew();
    }
  };

  const handleSaveNewOption = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (newOptionText.trim()) {
      const newOption = {
        id: newOptionText.toLowerCase().replace(/\s+/g, "-"),
        name: newOptionText.trim(),
      };
      APIService.postProject(company.id, { name: newOption.name }).then(
        (response) => {
          dispatch(
            updateSnackbar({
              snackbar: true,
              message: "New project created successfully!",
              type: "success",
            })
          );
          dispatch(setProjects((prev) => [...prev, response]));
          setUserSelectedProject(response.id);
          dispatch(setSelectedProject(response));
          PersistentStorageService.persistSelectedProject(response);
          resetAddNew();
          setIsDropdownOpen(false);
        },
        (err) => {
          console.log(err);
          dispatch(
            updateSnackbar({
              snackbar: true,
              message: err.message,
              type: "error",
            })
          );
          resetAddNew();
          setIsDropdownOpen(false);
        }
      );
    }
  };

  const onHandleMenu = (event) => {
    setAnchorElement(event.currentTarget);
  };

  const onHandleClose = () => {
    setAnchorElement(null);
  };

  const onHandleProfile = () => {
    onHandleClose();
    navigate(routeConfig.USER_SETTINGS);
  };

  const onHandleCompanySettings = () => {
    onHandleClose();
    navigate(routeConfig.COMPANY);
  };

  const onHandleLogout = () => {
    dispatch(logout());
    navigate(routeConfig.LOGIN);
  };

  const handleDrawerOpen = () => {
    setMobileNavOpen(true);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={isMobileNavOpen}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open navigation"
            onClick={handleDrawerOpen}
            edge="start"
            sx={[
              {
                mr: 3,
              },
              isMobileNavOpen && { display: "none" },
            ]}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }}>
            <FormControl
              variant="standard"
              sx={{ mx: 3, my: 1, minWidth: 240 }}
              size="small"
            >
              <InputLabel
                id="project-select-helper-label"
                disableAnimation={true}
                shrink={true}
                sx={{ color: "white" }}
              >
                Switch Project
              </InputLabel>
              <Select
                value={userSelectedProject}
                onChange={handleProjectChange}
                open={isDropdownOpen}
                onOpen={() => setIsDropdownOpen(true)}
                onClose={() => {
                  if (!isAddingNew) {
                    setIsDropdownOpen(false);
                    resetAddNew();
                  }
                }}
                MenuProps={menuProps}
                label="Switch Project"
                sx={{ color: "white" }}
              >
                {projects.map((project) => (
                  <MenuItem value={project.id} key={project.id}>
                    {project.name}
                  </MenuItem>
                ))}
                {!isAddingNew ? (
                  <MenuItem
                    value="add_new" // Added back the value prop
                    onMouseDown={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleAddNewClick(e);
                    }}
                    sx={{ cursor: "pointer" }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <Add />
                      Add new project
                    </Box>
                  </MenuItem>
                ) : (
                  <MenuItem onClick={(e) => e.stopPropagation()} disableRipple>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        width: "100%",
                        p: 1,
                      }}
                    >
                      <TextField
                        autoFocus
                        size="small"
                        value={newOptionText}
                        onChange={(e) => setNewOptionText(e.target.value)}
                        onKeyDown={handleKeyDown}
                        placeholder="Enter new project name"
                        sx={{ flex: 1 }}
                        onClick={(e) => e.stopPropagation()}
                        InputProps={{
                          onClick: (e) => e.stopPropagation(),
                        }}
                      />
                      <IconButton
                        size="small"
                        onClick={handleSaveNewOption}
                        onMouseDown={(e) => e.stopPropagation()}
                      >
                        <Check />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          resetAddNew();
                        }}
                        onMouseDown={(e) => e.stopPropagation()}
                      >
                        <Close />
                      </IconButton>
                    </Box>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings" placement="bottom-start">
              <IconButton
                aria-label={getInitials(`${user.first_name} ${user.last_name}`)}
                aria-haspopup="true"
                onClick={onHandleMenu}
                sx={{ p: 0 }}
                size="small"
              >
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  variant="dot"
                >
                  <Avatar
                    src={user.avatar}
                    alt={getInitials(`${user.first_name} ${user.last_name}`)}
                  >
                    {getInitials(`${user.first_name} ${user.last_name}`)}
                  </Avatar>
                </StyledBadge>
              </IconButton>
            </Tooltip>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElement}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElement)}
              onClose={onHandleClose}
              sx={{ mt: "45px" }}
            >
              <MenuItem onClick={onHandleProfile}>Profile</MenuItem>
              <MenuItem onClick={onHandleCompanySettings}>
                Company Settings
              </MenuItem>
              <MenuItem onClick={onHandleLogout}>Logout</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default TopBar;
